import React from "react"
import Page from "../../components/page"
import TextLeft from "../../components/textLeft"
import { graphql } from "gatsby"
import TextRight from "../../components/textRight"

export default function AboutAlbums({ location, data }) {
  return (
    <Page title={"Albums Details – PhotoTailors"} location={location}>
      <TextLeft
        title={<div>Perfectly <br/> Handmade</div>}
        subtitle=""
        text="We care about the details! Every album is handcrafted by our skilled artisans using only the best available materials. We’re raising the bar for both beauty and quality!"
        data={data}
        image={'albumsAboutHandmade'}
      />
      <TextRight
        title={<div>PRINTING</div>}
        subtitle=""
        text="We print on premium Fuji photographic paper. It was designed exclusively for high quality colour prints. It renders smooth gradients, giving images a better vibrance on lighter tones. We offer 4 formats: vertical, square, landscape and wide, with 3 size options for each format."
        data={data}
        image={'albumsAboutPrinting'}
      />
      <TextLeft
        title={<div>Lay <br/> FLAT TECHNOLOGY</div>}
        subtitle=""
        text="Our binding technology ensures there is no gap between pages. This flawless finish translates into a beautiful, seamless visual experience. You can thus enjoy all your photos and design in full. Layflat albums are great to showcase impressive panoramic spreads."
        data={data}
        image={'albumsAboutLayflat'}
      />
      <TextRight
        title={<div>Thick <br/> Pages</div>}
        subtitle=""
        text="Printed images are fused onto a hard heavyweight board core. The thick, robust core doesn’t bend or curl, giving your album an elegant feel. It is the elite’s preferred choice, reserved only for your most exceptional and defining moments."
        data={data}
        image={'albumsAboutThickPages'}
      />
      <TextLeft
        title={<div>MATERIALS <br/> AND FABRICS</div>}
        subtitle=""
        text="Pick your favourite between eco leather, genuine leather and European linen. These come in different colours and textures. You can check our swatches here."
        data={data}
        image={'albumsAboutFabrics'}
      />
      <TextRight
        title={<div>Cover <br/> styles</div>}
        subtitle=""
        text="Each unique cover style has its own personality – which of them matches your taste? Scroll through a selection of different cover finishes: print, deboss or acrylic inserts. Check our Cover Styles here."
        data={data}
        image={'albumsAboutStyles'}
      />
      <TextLeft
        title={<div>album <br/> boxes</div>}
        subtitle=""
        text="Keep your albums in top shape with our beautiful, eco-friendly bamboo boxes. They have a sleek look thanks to the invisible hinges and modern design lines. You will be surprised at how easy it is to store and use them. The bamboo boxes protect your most cherished memories so you can enjoy them years from now."
        data={data}
        image={'albumsAboutBoxes'}
      />
        <TextRight
          title={<div>album <br/> bags</div>}
          subtitle=""
          text="Looking for something simple, but still want that ‘Wow!’ effect? Choose our canvas bags! You will love to learn that they’re friendly with the environment but stand the test of time like no other. Want to try the canvas fabric out? You can check it here."
          data={data}
          image={'albumsAboutBags'}
        />
    </Page>
  )
}


export const query = graphql`
  query {
    albumsAboutHandmade: file(relativePath: { eq: "albumsAboutHandmade.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
   albumsAboutPrinting: file(relativePath: { eq: "albumsAboutPrinting.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  
  albumsAboutLayflat: file(relativePath: { eq: "albumsAboutLayflat.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
   
   albumsAboutThickPages: file(relativePath: { eq: "albumsAboutThickPages.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  } 
  
  albumsAboutFabrics: file(relativePath: { eq: "albumsAboutFabrics.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  } 
  
  albumsAboutStyles: file(relativePath: { eq: "albumsAboutStyles.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  
  albumsAboutBoxes: file(relativePath: { eq: "albumsAboutBoxes.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  
  albumsAboutBags: file(relativePath: { eq: "albumsAboutBags.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
   
  }
`